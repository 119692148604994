<template>
  <div class="container">
    <div class="locations-wrapper">
      <div class="faq-accordion" v-if="locations_data.length">
        <ul class="accordion accordion-2">
          <li class="accordion-item" :id="location.country_code" :ref="'location' + location.country_code.toLowerCase()" v-for="(location, i) in locations_data" :key="location.id">
            <a class="accordion-title" :class="setClass(i, location) ? 'active' : ''" href="javascript:void(0)">
              <i class="fa fa-plus"></i>
              <country-flag :country="location.country_code" size="normal"/>
              {{location.name}}
            </a>

            <div class="accordion-content" :style="setClass(i, location) ? 'display: block;' : ''">
              <div class="locations-cards">
                <div v-for="country in location.countries" :key="country.id" class="card-item">
                  <div class="img-wrapper">
                    <DefaultImage :id="country.image ? country.image.id : null" height="100%" width="100%" />
                  </div>
                  <div class="card-content">
                    <h3>{{country.title}}</h3>
                    <p v-if="country.address">
                      <i class="fa fa-crosshairs"></i>
                      {{country.country}}, {{country.address}}
                    </p>

                    <p v-if="country.email">
                      <i class="fa fa-envelope"></i>
                      {{country.email}}
                    </p>

                    <p v-if="country.phone">
                      <i class="flaticon-call"></i>
                      {{country.phone}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import CountryFlag from 'vue-country-flag';

  import DefaultImage from "./DefaultImage";
  import $ from "jquery";

  export default {
    name: "LocationCards",
    components: {
      DefaultImage,
      'country-flag': CountryFlag
    },
    props: ['locations'],
    data() {
      return {
        locations_data: [],
        location: ''
      }
    },
    methods: {
      groupByKey(array, key) {
        return array
          .reduce((hash, obj) => {
            if(obj[key] === undefined) return hash;
            return Object.assign(hash, { [obj[key]]:( hash[obj[key]] || [] ).concat(obj)})
          }, {})
      },
      checkUrl() {
        if (this.$route.query.l) {
          setTimeout(() => {
            this.location = this.$route.query.l;
            let area = this.$refs['location' + this.$route.query.l.toLowerCase()];
            if (area) {
              setTimeout(() => {
                area[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
              }, 200)
            }
          }, 0)
        }
      },
      setClass(i, item) {
        if (!this.location) {
          return i === 0
        } else {
          return this.location === item.country_code;
        }
      },
    },
    async mounted() {

      this.locations_data = await this.groupByKey(this.locations, 'country');

      let arr = [];
      for (const value of Object.entries(this.locations_data)) {
        arr.push({
          name: value[0],
          country_code: value[1][0].country_name.country_code,
          countries: value[1]
        })
      }
      this.locations_data = arr;

      this.checkUrl();

      $(function() {
        $('.accordion.accordion-2').find('.accordion-title').on('click', function(){
          // Adds Active Class
          $(this).toggleClass('active');
          // Expand or Collapse This Panel
          $(this).next().slideToggle('fast');
          // Hide The Other Panels
          $('.accordion-content').not($(this).next()).slideUp('fast');
          // Removes Active Class From Other Titles
          $('.accordion-title').not($(this)).removeClass('active');
        });
      });
    },
    watch: {
      '$route.query': {
        handler() {
          this.checkUrl();
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .default-img img {
    background: transparent;
  }
  .locations-wrapper {
    padding: 60px 0;

    .locations-cards {
      display: flex;
      flex-wrap: wrap;

      .card-item {
        width: 25%;
        padding: 10px;

        .img-wrapper {
          height: 200px;
        }

        .card-content {
          padding: 10px 0;

          h3 {
            font-size: 20px;
            font-weight: 400;
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
          }

          p {
            i {
              width: 18px;
              color: #FC4040;
            }
          }
        }
      }
    }
  }

  .faq-accordion .accordion .accordion-item {
    border-left: 0;
    border-right: 0;
    border-top: 0;

    .accordion-title .flag {
      margin-right: 0;
    }
  }

  @media (max-width: 1199px) {
    .locations-wrapper .locations-cards .card-item .img-wrapper {
      height: 145px;
    }
  }

  @media (max-width: 1023px) {
    .locations-wrapper .locations-cards .card-item {
      width: 33.333%;

      .img-wrapper {
        height: 200px;
      }
    }
  }

  @media (max-width: 991px) {
    .locations-wrapper .locations-cards .card-item {
      .img-wrapper {
        height: 145px;
      }

      .card-content {
        h3 {
          font-size: 17px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .faq-accordion .accordion .accordion-content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (max-width: 767px) {
    .locations-wrapper .locations-cards .card-item {
      width: 50%;
    }
    .faq-accordion .accordion .accordion-title i {
      bottom: 25px;
    }
  }

  @media (max-width: 465px) {
    .locations-wrapper .locations-cards .card-item {
      width: 100%;

      .img-wrapper {
        height: 200px;
      }
    }
  }
</style>
